<script>
/* eslint-disable */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-row  
      class="mb-2"
    >
      <b-col
        :class="{ myVisibility: arrayDDT.length === 0 }"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="success"
          @click="saveDDT"
        >
          {{ 'Salva Prezzi' }}
        </b-button>
      </b-col>
    </b-row>
    <b-form-file
      v-model="file"
      @input="bootstrapFile"
      multiple
      size="lg"
      accept=".xml, .DefXml"
      class="mb-5 drop-zone p-0"
    >
      <template slot="drop-placeholder">
        <b-row class="w-100 h-100 align-content-center position-absolute">
          <b-col class="d-flex flex-column align-items-center">
            <b-img
              :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
              width="70px"
              height="70px"
            />
            <h4>Trascina i Tuoi file qui</h4>
            <div>
            </div>
          </b-col>
        </b-row>
      </template>
      <template slot="placeholder">
        <b-row class="w-100 h-100 align-content-center position-absolute">
          <b-col class="d-flex flex-column align-items-center">
            <b-img
              :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
              width="70px"
              height="70px"
            />
            <h4>Trascina i Tuoi file qui...</h4>
            <h4>Oppure clicca qui</h4>
          </b-col>
        </b-row>
      </template>
    </b-form-file>
    <DDTDetails
      :documents="arrayDDT"
      @delete-ddt="deleteDDT"
    />
    <modal-load-price-confirm
      ref="modal-load-price-confirm"
      :ddt-prices="arraToJson"
    />
   <!-- <ejs-uploader
      ref="uploadObj"
      id="defaultfileupload"
      name="UploadFiles"
      :allowed-extensions ="extensions"
      :selected="readXmlAfterLoad"
    /> -->
    <!-- <ejs-uploader
      ref="uploadObj"
      id="defaultfileupload"
      name="UploadFiles"
      :sequentialUpload="'isSequential'"
      :autoUpload="isAuto"
      :asyncSettings="path"
      :removing="onFileRemove"
      :allowed-extensions ="extensions"
      :action-complete="prova"
      :selected="prova2"
    /> -->
  </b-card>
</template>
<script>
import Vue from 'vue';
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'

import { BCard, BFormFile, BRow, BCol, BButton, BImg } from 'bootstrap-vue'
// import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import axios from 'axios'
import parseXml from '@core/fromXMLtoJson/parseXml'
import xml2json from '@core/fromXMLtoJson/xml2json'
// import json2xml from '@core/fromXMLtoJson/json2xml'
import DocumentDDTDanea from '@core/class/ddt/index'
import DDTDetails from './components/DDTDetails.vue'
import ModalLoadPriceConfirm from './components/ModalLoadPriceConfirm.vue';

Vue.use(ToastPlugin);

export default {
  components: {
    BCard,
    BRow,
    BFormFile,
    DDTDetails,
    BCol,
    BButton,
    BImg,
    ModalLoadPriceConfirm,
  },
  watch: {
  },
  data(){
    return {
      file: [],
      arrayDDT: [],
      arraToJson: [], // ogni upload pusha toJson qui dentro
      toJson: {},
      xml: '',
      path:  {
        saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
        chunkSize: 500000,
      },
      dropElement: '.control-fluid',
      isSequential: false,
      isAuto: false,
      extensions: '.xml, .DefXml ',
      autoUpload: false,
      inputFile: {},
      }
    },
  methods:{
    onFileRemove: function (args) {
        args.postRawFile = false;
    },
    bootstrapFile(args) {
      args.forEach((el, index) => {
        this.arrayDDT = []
        const reader = new FileReader()
        if(this.file !== null || this.file !== undefined || this.file.length === 0) {
          reader.onload = res => {
            res.target.value = res.target.result
            // If JSON then perform JSON.stringify so file contents are not displayed as [object][object]
            // this.xml = JSON.stringify(res.target.result, undefined, '\t')
            // If XML then do not perform anything and directly load the contents to the textarea
            this.xml = res.target.result
            this.convertToJSON(this.xml)
            /*
              CONDZIONI:
              1 IF- CONTROLLA SE IL DOCUMENTO è DI DANEA
              2 IF - CONTROLLA SE IL DDT CONTIENE UN ARRAY DI DDT E NEL CASO IN CUI FOSSE COSI FA UN PUSH PER OGNI ELEMENTO
              3 ELSE IF- CONTROLLA SE E' UN SINGOLO DDT E PUSHA
              4 ELSE - NON è DI DANEA ALERT
            */
            if ('EasyfattDocuments' in this.toJson) {
              // console.log('json', this.toJson)
              if(Object.prototype.toString.call(this.toJson.EasyfattDocuments.Documents.Document) === '[object Array]' && 'EasyfattDocuments' in this.toJson) {
                const spreadArrayProva = { ...this.toJson.EasyfattDocuments.Documents }
                for(let i = 0; i <= spreadArrayProva.Document.length - 1; i++) {
                  let daneaDDT = new DocumentDDTDanea(this.toJson.EasyfattDocuments.Company, spreadArrayProva.Document[i], this.toJson.EasyfattDocuments["@Creator"])
                  // console.log(daneaDDT, this.toJson)
                  daneaDDT.id = daneaDDT.documents.Number.concat(daneaDDT.documents.Numbering)
                  const findDDTinArray = this.arrayDDT.findIndex(ddt => ddt.documents.Number.concat(ddt.documents.Numbering) === daneaDDT.documents.Number.concat(daneaDDT.documents.Numbering))
                  if(findDDTinArray < 0) {
                    // console.log(findDDTinArray, 'minore')
                    this.arrayDDT.unshift(daneaDDT)
                  } else {
                    this.$refs.toastRef.show({
                      title: 'Operazione Fallita!', content: `Hai già inserito il DDT: ${daneaDDT.documents.Number.concat(daneaDDT.documents.Numbering)} `, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
                    })
                  }
                }
              } else if(Object.prototype.toString.call(this.toJson.EasyfattDocuments.Documents.Document) === '[object Object]' && 'EasyfattDocuments' in this.toJson) {
                let daneaDDT = new DocumentDDTDanea(this.toJson.EasyfattDocuments.Company, this.toJson.EasyfattDocuments.Documents.Document, this.toJson.EasyfattDocuments["@Creator"])
                // console.log(daneaDDT.documents.Number.concat(daneaDDT.documents.Numbering))
                this.arrayDDT.unshift(daneaDDT)
              }
            }
            else {
              this.file.splice(index, 1)
              this.alertDaneaFails()
            }
            // console.log('this.arrayDDT', this.arrayDDT)
            // console.log(this.$refs['uploadObj'])
            }
            reader.onerror = err => {
              // console.log('Error : ', err)
              return err
            }
            reader.readAsText(el)  
        }
      })
      this.file.splice(0)
    },
    readXmlAfterLoad(args) {
      if(!args.isCanceled) {
        let file = args.filesData[0].rawFile
        // console.log('aciont-complete prova args', file)
        const reader = new FileReader()
        if(file !== null || file !== undefined) {
          reader.onload = res => {
            res.target.value = res.target.result
            // If JSON then perform JSON.stringify so file contents are not displayed as [object][object]
            // this.xml = JSON.stringify(res.target.result, undefined, '\t')
            // If XML then do not perform anything and directly load the contents to the textarea
            this.xml = res.target.result
            this.convertToJSON(this.xml)
            if ('EasyfattDocuments' in this.toJson) this.arrayDDT.push(this.toJson)
            else {
              // this.$refs.uploadObj.ej2Instances.filesData = this.$refs.uploadObj.ej2Instances.filesData[0].pop();
              this.$refs.uploadObj.postRawFile = false; 
              this.alertDaneaFails()
            }
           }
           reader.onerror = err => {
             // console.log('Error : ', err)
             return err
           }
           reader.readAsText(file)  
        }
      }
    },
    convertToJSON(xml) {
      this.arrayDDT = []
      const dom = parseXml(xml)
      let json = xml2json(dom)
      // json = json.substring(1, 11)
      const removeUndefinedFromString = json.split('undefined')
      this.toJson = removeUndefinedFromString[0] + removeUndefinedFromString[1]
      this.toJson = JSON.parse(this.toJson)
/*       if (Object.prototype.toString.call(this.toJson.EasyfattDocuments.Documents.Document) === '[object Object]' && 'EasyfattDocuments' in this.toJson) {
        this.toJson.EasyfattDocuments.Documents.Document = Object.entries(this.toJson.EasyfattDocuments.Documents).map((e) => e[1])
      } */
      //this.arraToJson.push(this.toJson)
      // console.log('converasione', this.toJson)
      // console.log('provaaa', this.toJson.EasyfattDocuments.Documents.Document.Rows)
    },
    deleteElementFromUploader() {

    },
    alertDaneaFails() {
      this.$refs.toastRef.show({
        title: 'Operazione Fallita!', content: `Hai provato ad inserire un DDT non di DANEA`, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
      })
    },
    deleteDDT(index) {
      // console.log('arrayDDT', this.arrayDDT, index)
      this.arrayDDT.splice(index, 1)
      // console.log('arrayDDT dopo splice', this.arrayDDT, index)

    },
    async saveDDT() {
      /* console.log('prima', this.arraToJson)
      this.arraToJson = this.arraToJson.map(ddt => {
        if (Object.prototype.toString.call(ddt.EasyfattDocuments.Documents.Document) === '[object Object]' && 'EasyfattDocuments' in ddt) {
          const ddtnew = ddt.EasyfattDocuments.Documents.Document = Object.entries(ddt.EasyfattDocuments.Documents).map((e) => e[1])
          return ddtnew
        }
        return ddt
      })
      console.log('dopo', this.arraToJson) */
      // console.log('prima', this.toJson)
      if (Object.prototype.toString.call(this.toJson.EasyfattDocuments.Documents.Document) === '[object Object]' && 'EasyfattDocuments' in this.toJson) {
        this.toJson.EasyfattDocuments.Documents.Document = Object.entries(this.toJson.EasyfattDocuments.Documents).map((e) => e[1])
      }
      const setProduct = [...this.toJson.EasyfattDocuments.Documents.Document.map(el => el.Rows.Row)]
      // console.log(setProduct)
      let arrayTotal = []
      const allProduct = setProduct.map(el => el)
      allProduct.forEach(el => {
        el.forEach(el1 => {
          arrayTotal.push(el1)
        })
      })
      // console.log(arrayTotal)
      let arrayToSend = arrayTotal.map(el => {
        const product = {
          id: 0,
          codice: el.Code,
          attivo: 1,
          visibile: 1,
          disponibile: 1,
          inEvidenza: 0,
          nome: el.Description,
          descrizioneBreve: el.Description,
          statoServizio: 'Operativo',
          note: el.Description,
          costo: el.Price,
          prezzoVendita: el.Price,
          categorie: [{ id: 1 }],
          aliquotaIva: { id: 2 },
          tipoProdotto: { id: 1 },
          setAttributi: { id: 1 },
          unitaMisura: { id: 2 },
          unitaUtilizzo: { id: 2 },
        }
        return product
      })
      let b = []

      for(let i = 0; i< arrayToSend.length;++i){
        b.push(arrayToSend[i])
        /* b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i])
        b.push(arrayToSend[i]) */
      }
      arrayToSend = b
      // arrayToSend()
       await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([...arrayToSend]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Prodotto',
            metodo: 'setProdottoGenerale',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log('edited', res)
        if (res.data.esito === 'OK') {
          // this.productAddedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Prodotto/i Inserito/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
          })
        }
        }).catch(e => e)
      // this.arraToJson = setProduct
      // this.$refs['modal-load-price-confirm'].openModal()
      /* await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(this.toJson),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-Magazzino',
            metodo: 'setImportDdtDanea',
            ACL: '',
          },
        }).then(res => {
        // console.log('DDT', res)
        if (res.data.esito === 'OK') {
          // this.$store.dispatch('catalogs/setCatalogs', res.data.cataloghi)
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Quantità DDT aggiunte Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        }
      })
        .catch(e => e) */
    },
  },
}
</script>
<style>

.control-section {
    height: 100%;
    min-height: 240px;
}
label.custom-file-label {
   background: #f8f8f8 !important;

}
.drop-zone {
  background: #f8f8f8 !important;
  border: 2px dashed #7367f0;
  min-height: 250px;
  width:100%;
  content: 'ciao'
}

.b-form-file.b-custom-control-lg .custom-file-label::after {
    height: 44px;
    visibility: hidden;
}
.myVisibility{
  visibility: hidden;
}

</style>
